import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';    
import{MDBContainer} from 'mdbreact';

class GalleryPage extends React.Component{

    render(){
        return(
            <Layout fancyHeader={false}>
                <section id='gallery' className='gallerySection'>
                    <h1>Gallery</h1>
                    <MDBContainer >
                        <Gallery thumbnails={this.props.data.galleryThumbs.edges}
                            modalPhotos={this.props.data.modalPhotos.edges}
                            btnHref='/#portfolio'
                            btnText='Portfolio'/>
                    </MDBContainer>
                </section>
            </Layout>
        )
    }
}

export const query = graphql`
    query {
        galleryThumbs:
            allFile( filter: {relativePath: {regex:"/Gallery/"}})
            {
                edges{
                    node{
                        id
                        childImageSharp{
                            fluid(maxWidth:500 quality:72){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            },
        modalPhotos:
            allFile( filter: {relativePath: {regex:"/Gallery/"}})
            {
                edges{
                    node{
                        id
                        childImageSharp{
                            fluid(maxWidth:3840, quality:72){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
    }
`

export default GalleryPage